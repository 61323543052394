// export default {
//   api: 'http://192.168.1.7:8000/api/v1',
//   imageUrl: 'https://192.168.1.7:8000/'

// };
export const house_labour_id = 1

export const api = "https://cirgecoapi.indutechit.com/api/v1";
export const imageUrl = "https://cirgecoapi.indutechit.com/";
// export const loginapi = "http://192.168.1.3:8000/api/v1"
