import { Injectable } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { messages } from '../pages/extra-components/chat/messages';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private toastrService: NbToastrService) { }

  errorToastTR(message: string) {
    let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    let status: NbComponentStatus = 'danger';
    let title: any = 'Error';
    let duration: number = 5000
    this.toastrService.show(message, title, { position, status,duration })

  }
  successToastTR(message: string, title: string = 'Success') {
    let position: any = 'top-right';
    let status: any = 'success';
    let duration: number = 5000
    this.toastrService.show(message, title, { position, status ,duration})

  }

  // deleteToastTR(message: string) {
  //   let position: any = 'top-right';
  //   let status: any = 'warning';
  //   let title: any = 'Delete';
  //   this.toastrService.show(message, title, { position, status })

  // }

  // updateToastTR(message: string) {
  //   let position: any = 'top-right';
  //   let status: any = 'success';
  //   let title: any = 'Update';
  //   this.toastrService.show(message, title, { position, status })
  // }

}
