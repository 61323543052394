import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { AlertService } from '../_services/alert.service';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
    private alertService:AlertService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
        // location.reload(true);
      }

      const val = err.error.errors

      if (val) {
        for (const key of Object.keys(val)) {

          this.alertService.errorToastTR(val[key][0])
          // console.log(Object.keys(val))
        }
      }
      else if (err.error.errorMsg) {

        this.alertService.errorToastTR(err.error.errorMsg)
      }

      //const error = err.error.message || err.statusText;
      return throwError(err);
    }))
  }
}
