import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
// import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  languages = ['en', 'hin'];
  languages1 = [
    {
      key: 'en',
      value: 'English'
    },
    {
      key: 'hin',
      value: 'Hindi'
    }

  ];

  selectedLanguage?= ''



  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;




  // themes = [
  //   {
  //     value: 'default',
  //     name: 'Light',
  //   },
  //   {
  //     value: 'dark',
  //     name: 'Dark',
  //   },
  //   {
  //     value: 'cosmic',
  //     name: 'Cosmic',
  //   },
  //   {
  //     value: 'corporate',
  //     name: 'Corporate',
  //   },
  // ];

  currentTheme = 'default';

  // userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    // private userService: UserData,
    private layoutService: LayoutService,
    public authservice: AuthenticationService,
    public translate: TranslateService,
  ) {
    // translate.addLangs(this.languages);

    // translate.setDefaultLang('en')


  }

  handleLanguage = (event: any) => {
    console.log({ event })
    this.translate.use(event);
  }

  ngOnInit() {
    // this.translate.use(this.translate.getBrowserLang());
    this.selectedLanguage = this.translate.currentLang;


    // console.log('HEADER_SELECLEENG: ', this.selectedLanguage)
    this.currentTheme = this.themeService.currentTheme;

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);

    // const { xl } = this.breakpointService.getBreakpointsMap();
    // this.themeService.onMediaQueryChange()
    //   .pipe(
    //     map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    // this.themeService.onThemeChange()
    //   .pipe(
    //     map(({ name }) => name),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe(themeName => this.currentTheme = themeName);
  }

  // ngOnDestroy() {
  //   this.destroy$.next();
  //   this.destroy$.complete();
  // }

  // changeTheme(themeName: string) {
  //   this.themeService.changeTheme(themeName);
  // }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  items: NbMenuItem[] = [
    {
      title: 'Profile',
      // expanded: true,
      // badge: {
      //   text: '30',
      //   status: 'primary',
      // },
      children: [
        {
          title: 'profile',
          icon: 'edit-2-outline',
        },

        {
          title: 'Log out',
          // badge: {
          //   text: '99+',
          //   status: 'danger',
          // },
        },


      ],
    },
  ]



  logout() {
    this.authservice.logout()
  }

}
