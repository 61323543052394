import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitString'
})
export class SplitStringPipe implements PipeTransform {

  transform(value: string, saparator = " @ "): string {
    let newString = value.split(saparator);
    return newString[0]
  }

}
