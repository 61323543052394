<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline" style="color: white;"></nb-icon>
    </a>




    <!-- <nb-select selected="1" id="breadSize" style="width: 100%;">
    <nb-option value="">...choose</nb-option>
    <nb-option value="">Small</nb-option>
    <nb-option value="1">Medium</nb-option>
    <nb-option value="">Large</nb-option>
  </nb-select> -->

    <!-- <nb-select [(selected)]="selectedLanguage" status="success" (selectedChange)="handleLanguage($event)">
      <nb-option *ngFor=" let language of languages1" [value]="language.key"> {{
        language.value }}</nb-option>
    </nb-select> -->

    <img src="../../../../assets/images/cirg_logo1-removebg-preview.png" alt=""
      style="width: 80px; margin-left: 20px; border-radius: 10px; height: 60px; object-fit: contain;">


  </div>

</div>

<div class="header-container">
  <h4 class="logo"> CIRG- Goat Model Project</h4>
</div>


<div class="header-container">
  <nb-actions size="small">


    <nb-action class="user-action" *nbIsGranted="['view', 'user']">




      <button  nbButton status="danger" (click)="logout()">Log out </button>

      <!-- <nb-menu [items]="items">
      </nb-menu> -->




    </nb-action>
  </nb-actions>
</div>
