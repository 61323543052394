import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// import { environment } from '@environments/environment';
import { User } from '../_models';
import { api } from '../_helpers';
import { Router } from '@angular/router';
import { state } from '@angular/animations';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User | any>;
  public currentUser: Observable<User | any>;


  private currentTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('token'));
  public currentToken: Observable<string> = this.currentTokenSubject.asObservable();

  constructor(private http: HttpClient,
    private router: Router,) {

    const currentUser = localStorage.getItem('currentUser');
    let user = null;
    if (currentUser)

      user = JSON.parse(currentUser);
    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentUser = this.currentUserSubject.asObservable();

    if (user) {
      this.currentTokenSubject.next(user.token);

    }

  }

  public get currentUserValue(): User {

    return this.currentUserSubject.value;
  }



  public get currentTokenValue(): string {
    return this.currentTokenSubject.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${api}/login`, { username, password })


      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', JSON.stringify(data.token));
        this.currentTokenSubject.next(data.token);
        return data;
      }));
  }

  setUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }


  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login'])


    console.log("data remove succesfully in local storage")

    // this.currentUserSubject.next(null);
  }
}
